import 'slick-carousel'

import momemt from 'moment';

import AOS from 'aos';



jQuery( document ).ready(function($) {



  AOS.init({

    duration: 1000,

    once: true,

    offset: 200,

  });

  

  let scrollRef = 0;

  window.addEventListener('scroll', () => scrollRef <= 10 ? scrollRef++ : AOS.refresh() );



  // MOBILE MENU

  $('.menu-trigger').click(function(e){

    e.preventDefault()

    $('.menu').addClass('active')

    $('.menu-top').show()

  })



  $('.menu-close').click(function(e){

    e.preventDefault()

    $('.menu').removeClass('active')

    $('.menu-top').hide()

  })

  

  $('.menu li a').click(function(e){

    $('.menu').removeClass('active')

    $('.menu-top').hide()

  })



  // MUSIC SLIDER

  $('.slider-music').slick({

    slidesToShow: 1,

    slidesToScroll: 1,

    mobileFirst: true,

    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',

    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',

    arrows: false,

    autoplay: true,

    autoplaySpeed: 0,

    speed: 2000,

    cssEase:'linear',

    infinite: true,

    // focusOnSelect: false,

    centerMode: true,

    responsive: [

      {

        breakpoint: 992,

        settings: {

          slidesToShow: 5,

        }

      }

    ]

  });



  $.ajax( {

    url: 'https://rest.bandsintown.com/artists/Ruel/events?app_id=45PRESS_RUEL',

    method: 'GET',

    dataType: 'json',

    error: () => {

      alert( 'Error fetching events!' );

    },

    success: data => {

    const events = $( '#events' );

    let html = '';

    if ( data.length ) {

      for ( let event of data ) {

        let region = event.venue.region?event.venue.region:event.venue.country

        let location = event.venue.city + ', ' + region;

        if ( location === ', ' ) {location = '';}

        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}

        html += '<div class="event">';

        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';

        html += '<div class="event-location">' + location + '</div>';

        html += '<div class="event-venue">' + event.venue.name + '</div>';

        html += '<div class="event-links">';

        for ( let offer of event.offers ) {

          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-underline">Buy ' + offer.type + '</a>';

        }

        html += '</div>';

        html += '</div>';

      }

      events.html( html );

      } else {

        events.html( 'No upcoming events.' );

        $('.all-dates').hide()

      }

    }

  });



  $('.all-dates').click(function(e){

    e.preventDefault()

    $('#events').toggleClass('all')

    if($(this).html() === 'view more dates'){

      $(this).html('view less dates')

    } else{

      $(this).html('view more dates')

    }

  })



  // FORM

  $("#ruel-newsletter-signup-2021, #ruel-newsletter-signup-2021-2").on("submit", function (e) {

    // Prevent default browser actions on form submission.

    e.preventDefault();

    // Get all the form user submitted data and get it ready to be sent.

    var data = $(this).serialize();

    // POST all the data to the SMF submission endpoint URL.

    $.ajax({

      type: "POST",

      url: "https://subs.sonymusicfans.com/submit",

      dataType: "json",

      data: data,

      xhrFields: {

        withCredentials: false

      },

      success: function (data) {

        // Do things on successful submission.

        $('.newsletter-error').hide();

        $('#ruel-newsletter-signup-2021, #ruel-newsletter-signup-2021-2').hide();

        $('.newsletter-success').show();

      },

      error: function (err) {

        // Do things when submission has an error.

        $('.newsletter-error').show();

      }

    });

  });



  // LYRICS

  const marquee = $('.notice');

  marquee.each(function() {

      var mar = $(this),indent = mar.width();

      mar.marquee = function() {

          indent--;

          mar.css('text-indent',indent);

          if (indent < -1 * mar.children('.body').width()) {

              indent = mar.width();

          }

      };

      mar.data('interval',setInterval(mar.marquee,1000/60));

  });



  const lyrics = [

    {

      body: `yeah, i lost my head and i’d do it again`,

      name: `dazed and confused`

    },

    {

      body: `i let out too many feelings`,

      name: `too many feelings`

    },

    {

      body: `all these words left unsaid, hanging over my head`,

      name: `unsaid`

    },

    {

      body: `deep in my heart i know that it’s over`,

      name: `younger`

    },

    {

      body: `dizzy silhouette, makes me break a sweat, i’m in trouble`,

      name: `Dazed and Confused`

    },

    {

      body: `as long as you’re here, i’m all bright lights and red eyes`,

      name: `as long as you care`

    },

    {

      body: `don’t cry i know that you care`,

      name: `as long as you care`

    },

    {

      body: `i didn’t mean to be cold guess it’s just a sore throat`,

      name: `say it over`

    },

    {

      body: `feel like the one but i’m one in a billion`,

      name: `painkiller`

    },

    {

      body: `well i just wanna know what you’re thinking`,

      name: `distance `

    },

    {

      body: `i’ve been feeling so high on the way down`,

      name: `distance`

    },

    {

      body: `drown myself, so i don't sink`,

      name: `let the grass grow`

    },

    {

      body: `i'd try again, but i don't have the courage`,

      name: `courage`

    },

    {

      body: `I know you feel it, but you always rather bottle it up`,

      name: `you against yourself`

    },

    {

      body: `All of my friends said, "Don't worry, I promise you'll be fine"`,

      name: `free time`

    },

    {

      body: `When I see that you been crying But you never wanna talk about it`,

      name: `unsaid`

    }

  ]

  const lyricBody = $('.lyric .body')

  const lyricName = $('#lyrics .title')

  let lyricTarget = Math.floor(Math.random() * lyrics.length);

  lyricBody.html(lyrics[lyricTarget].body)

  lyricName.html(`- ${lyrics[lyricTarget].name} -`)





  // Apple

  const togglePopup = () => {

    $('.popup').toggleClass('active')

  }



  $('.popup .close').click(function(){

    togglePopup()

  })



  $('.btn-apple').click(function(e){

    e.preventDefault()

    togglePopup()



    // Create a new SMEAppleMusic object with your parameters.

    var sm = new SMEAppleMusic({

      ae: {

        ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",

        brand_id: "3448657",

        segment_id: "1747102",

        activities: '{"actions":{"presave":105002},"mailing_list_optins":{"a0S1p00000Q3MGGEA3":105003}}'

      },

      am: {

        dev_token: "",

        save_mode: "library",

        custom_playlist_name: "",

        new_apple_presave: true,

        resources: {

          playlists: [],

          albums: []

        }

      },

      sf: {

        form: "252933",

        default_mailing_list: ""

      },

      smf: {

        campaign_id: "252933",

        campaign_key: "94614b8136b7925c238573a0db1425f0"

      }

    });

    document.getElementById("apple_music_form").addEventListener("submit", function (e) {

      e.preventDefault();

      // Grab the email address value.

      var email = document.getElementById("apple_music_email_address").value;

      // Pass the email address and opt in status

      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.

      sm.doActions(email, false, ["a0S1p00000Q3MGGEA3"]).then(function (res) {

        $('.popup').removeClass('active')

        $('body').addClass('thank-you')

      }).catch(function (err) {

        $('.popup').removeClass('active')

      });

    });



  })



  $('.ty-close').click(function(){

    $('body').removeClass('thank-you')

  })



  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const state = urlParams.get('state')

  if(state) {

    $('.popup').removeClass('active')

    $('body').addClass('thank-you')

  }





});